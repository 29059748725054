import {
	BLOCK_SLOT_FOOTER,
	BLOCK_TYPE_NAVIGATION,
	BLOCK_TYPE_STICKY_BAR,
	BLOCK_TYPE_ECOMMERCE_PRODUCT,
	PAGE_TYPE_ECOMMERCE_PRODUCT,
	SYSTEM_LOCALE,
	PAGE_TYPE_ECOMMERCE_DYNAMIC_PRODUCT,
	BLOCK_TYPE_ECOMMERCE_PRODUCT_LIST,
} from '@zyro-inc/site-modules/constants';
import { getLanguageSwitcherLanguages } from '@zyro-inc/site-modules/utils/getLanguageSwitcherLanguages';
import { filterLanguageHiddenPages } from '@zyro-inc/site-modules/utils/filterHiddenPages';
import {
	SiteBlocks,
	SiteData,
	SiteElement,
	SiteElements,
	SitePage,
	SitePages,
	SitePageData,
} from '@hostinger/builder-schema-validator';
import {
	getHomepageId,
	getIsSlugHomepage,
} from '@zyro-inc/site-modules/utils/page/getPageIdFromPath';
import { getIsDynamicProductPageEnabled } from '@zyro-inc/site-modules/utils/ecommerce';

const GLOBAL_BLOCK_TYPES = [
	BLOCK_TYPE_NAVIGATION,
	BLOCK_TYPE_STICKY_BAR,
];

export const getPageBlocks = ({
	siteBlocks,
	pageBlocksIds,
}: {
	siteBlocks: Record<string, any>,
	pageBlocksIds: string[]
}) => Object.fromEntries(Object.entries(siteBlocks).filter(([
	blockId,
	{
		type,
		slot,
	},
]) => {
	const isPageBlock = pageBlocksIds.includes(blockId);
	const isGlobalBlock = GLOBAL_BLOCK_TYPES.includes(type);
	const isFooterBlock = slot === BLOCK_SLOT_FOOTER;

	return isPageBlock || isGlobalBlock || isFooterBlock;
}));

// Needed in order to get correct BlockEcommerceProduct data when opening EcommerceProductPreview in BlockEcommerceProductListProviderUser
// Ecommerce product blocks will be included only in that case if page contains product list block
export const getEcommerceProductBlocks = ({
	siteBlocks,
	pageBlocksIds,
}: {
	siteBlocks: Record<string, any>,
	pageBlocksIds: string[]
}) => {
	const doesPageHaveProductListBlock = Object.entries(siteBlocks).find(([id, data]) => {
		const isPageBlock = pageBlocksIds.includes(id);

		return isPageBlock && data.type === BLOCK_TYPE_ECOMMERCE_PRODUCT_LIST;
	});

	if (!doesPageHaveProductListBlock) {
		return {};
	}

	return Object.fromEntries(Object.entries(siteBlocks).filter(([, { type }]) => type === BLOCK_TYPE_ECOMMERCE_PRODUCT));
};

export const getPageElementsFromPageBlocks = ({
	siteElements,
	blocks,
	isCustomCodeDisabled,
}: {
	siteElements: SiteElements,
	blocks: SiteBlocks,
	isCustomCodeDisabled: boolean
}) => {
	const pageBlocksElementIds = Object.values(blocks).map(({ components }) => components).flatMap((components) => components);

	const removeEmbeddedCodeContent = (elementValue: SiteElement) => {
		if (isCustomCodeDisabled && elementValue.type === 'GridEmbed') {
			return {
				...elementValue,
				content: '',
			};
		}

		return elementValue;
	};

	return Object.fromEntries(Object.entries(siteElements)
		.map(([elementId, elementValue]) => [
			elementId,
			removeEmbeddedCodeContent(elementValue),
		])
		.filter(([elementId]) => pageBlocksElementIds.includes(elementId as string)));
};

/**
 * For password protected pages, removes blocks and password value from page data
 */
export const normalizePage = ({
	page,
	isPreviewMode,
}: {
	page: SitePage,
	isPreviewMode: boolean
}) => {
	if (!page) {
		return null;
	}

	if (!page?.meta?.password || isPreviewMode) {
		return page;
	}

	return {
		...page,
		meta: {
			...page.meta,
			password: true,
		},
		blocks: [],
	};
};

export const getPageData = ({
	siteData,
	locale,
	pageId,
	isAstro,
	isPreviewMode,
	isCustomCodeDisabled,
}: {
	siteData: SiteData,
	locale: string,
	pageId: string,
	isAstro: boolean,
	isPreviewMode: boolean,
	isCustomCodeDisabled: boolean,
}): SitePageData | null => {
	const { defaultLocale = SYSTEM_LOCALE } = siteData.meta;

	const legacyProductPages = Object.fromEntries(Object.entries(siteData.languages[defaultLocale].pages)
		.filter(([, { type }]) => type === PAGE_TYPE_ECOMMERCE_PRODUCT));

	const productPageIds = Object.entries(legacyProductPages)
		.map(([id]) => id);

	const languageData = siteData.languages[locale];

	const {
		blocks: defaultLocaleBlocks,
		elements: defaultLocaleElements,
	} = siteData.languages[defaultLocale];

	const page = normalizePage({
		page: languageData.pages[pageId],
		isPreviewMode,
	});

	if (!page) {
		return null;
	}

	const doesLocalizedFooterExist = Object.values(languageData.blocks).some(({ slot }) => slot === BLOCK_SLOT_FOOTER);
	const defaultBlocks = doesLocalizedFooterExist ? Object.entries(defaultLocaleBlocks).filter(([_, block]) => block.type as string !== 'footer') : defaultLocaleBlocks;

	const pageBlocks = getPageBlocks({
		// destructure order is important because page blocks share same blockIds
		// thus defaultLocaleBlocks need to be overridden to retain current page block data
		siteBlocks: {
			...defaultBlocks,
			...languageData.blocks,
		},
		pageBlocksIds: [
			...page?.blocks || [],
			...productPageIds,
		],
	});
	const ecommerceProductBlocks = getEcommerceProductBlocks({
		siteBlocks: {
			...defaultBlocks,
			...languageData.blocks,
		},
		pageBlocksIds: [
			...page?.blocks || [],
			...productPageIds,
		],
	});

	const pageElements = getPageElementsFromPageBlocks({
		siteElements: {
			...defaultLocaleElements,
			...languageData.elements,
		},
		blocks: {
			...pageBlocks,
			...ecommerceProductBlocks,
		},
		isCustomCodeDisabled,
	});

	// Filter not published pages
	const filteredPages = filterLanguageHiddenPages(languageData.pages, new Date().setHours(0, 0, 0, 0), isAstro, isPreviewMode);
	const normalizedPages = Object.fromEntries(Object.entries(filteredPages).map(([pageKey, pageData]) => [
		pageKey,
		normalizePage({
			page: pageData as SitePage,
			isPreviewMode,
		}),
	]));

	return {
		pages: {
			...normalizedPages as SitePages,
			// legacyProductPages are required for BlockEcommerceProductList
			...legacyProductPages,
		},
		blocks: {
			...pageBlocks,
			...ecommerceProductBlocks,
		},
		elements: pageElements,
		nav: languageData.nav,
		currentLocale: locale,
		homePageId: languageData.homePageId,
		isNavHidden: languageData.isNavHidden,
		cookieBannerAcceptText: languageData.cookieBannerAcceptText,
		cookieBannerDisclaimer: languageData.cookieBannerDisclaimer,
		cookieBannerDeclineText: languageData.cookieBannerDeclineText,
		blogReadingTimeText: languageData.blogReadingTimeText,
		metaTitle: languageData.metaTitle,
		meta: siteData.meta,
		forms: siteData.forms,
		styles: siteData.styles,
		domain: siteData.domain ?? siteData.freeDomain,
		siteId: siteData.siteId,
		ecommerceShoppingCart: siteData.ecommerceShoppingCart,
		blogCategories: siteData.blogCategories,
		languageSwitcherLanguages: getLanguageSwitcherLanguages({
			languages: siteData.languages,
			defaultLocale: siteData.meta.defaultLocale,
		}),
		currentPageId: pageId,
		productId: page.productId,
		languageKeys: Object.keys(siteData.languages),
		isDynamicProductPageEnabled: getIsDynamicProductPageEnabled({
			defaultLocalePages: siteData?.languages[defaultLocale]?.pages,
		}),
	};
};

export const getShouldLoadDynamicProductPageByPageId = ({
	pageId,
	website,
	path,
	defaultLocale,
}: {
	pageId: string,
	website: SiteData,
	path: string,
	defaultLocale: string
}) => {
	const isDynamicProductPageEnabled = getIsDynamicProductPageEnabled({
		defaultLocalePages: website?.languages[defaultLocale]?.pages,
	});
	const homepageId = getHomepageId({
		siteData: website,
		path,
	});

	// 1. if pageId falls back to homepageId, it means that the page not found
	// 2. double check if the slug belongs to homepage in case its really homepage
	// 3. check if dynamic product page is enabled
	return homepageId === pageId && !getIsSlugHomepage({
		siteData: website,
		path,
	}) && isDynamicProductPageEnabled;
};

export const getDynamicProductPageData = ({
	website,
	defaultLocale,
}: {
	website: SiteData,
	defaultLocale: string,
}) => {
	const dynamicProductPageId = Object.keys(website.languages[defaultLocale].pages).find((id) => {
		const page = website?.languages[defaultLocale].pages[id];

		return page?.type === PAGE_TYPE_ECOMMERCE_DYNAMIC_PRODUCT;
	}) as string;

	return getPageData({
		siteData: website,
		locale: defaultLocale,
		pageId: dynamicProductPageId,
		isAstro: false,
		isPreviewMode: false,
		isCustomCodeDisabled: website.meta.isCustomCodeDisabled || false,
	});
};
