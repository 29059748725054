import {
	META_ECOMMERCE_TYPE,
	ECOMMERCE_TYPE_ZYRO,
	PAGE_TYPE_ECOMMERCE_PRODUCT,
	PAGE_TYPE_ECOMMERCE_DYNAMIC_PRODUCT,
} from '@zyro-inc/site-modules/constants';
import { getStoreId } from '@zyro-inc/site-modules/utils/getters/getStoreId';
import { getCartData } from '@zyro-inc/site-modules/utils/ecommerce/cartData';
import {
	getVariantsQuantity,
	getCategories,
	getStoreShippingOptions,
	getStoreRegions,
	getStoreProductsByIds,
} from '@zyro-inc/site-modules/api/StoreApi';
import {
	MAX_PRODUCTS_IN_CART,
	SHOPPING_CART_STORAGE_KEY,
	SHOPPING_CART_TTL,
} from '@zyro-inc/site-modules/constants/ecommerce';
import {
	EcommerceProductType,
	EcommerceRegion,
	EcommerceShippingOption,
	EcommerceCollection,
	EcommerceProduct,
	EcommerceProductCustomField,
	EcommerceProductVariantQuantity,
	EcommerceRegionShippingOptions,
} from '@zyro-inc/site-modules/types';

import {
	ref,
	computed,
} from 'vue';

import type { SitePage } from '@hostinger/builder-schema-validator';

import { useSiteGlobal } from '@zyro-inc/site-modules/use/useSiteGlobal';
import {
	getIsSameCustomField,
	getIsFreeProductTypeFree,
} from '@zyro-inc/site-modules/utils/ecommerce/productUtils';

const categories = ref<EcommerceCollection[]>([]);
const shoppingCartItems = ref<any[]>([]);
const variantsQuantity = ref<EcommerceProductVariantQuantity[]>([]);
const selectedBookingProduct = ref<EcommerceProduct | null>(null);

const isShoppingCartOpen = ref(false);
const isCheckoutLoading = ref(false);
const isLoadingValues = ref<Record<string, boolean>>({});
const isLoadedValues = ref<Record<string, boolean>>({});
const isCategoriesLoaded = ref(false);
const isProductPageLoaded = ref(false);

export const getExistingProductWithCustomField = (
	quantifiedProducts: Array<{product: EcommerceProduct[], quantity: number}>,
	productToMatch: EcommerceProduct,
) => quantifiedProducts.find((searchedProduct: any) => {
	const isSameProduct = searchedProduct.product.variants[0].id === productToMatch.variants[0].id;

	if (searchedProduct.product.custom_fields?.length) {
		const isSameCustomField = searchedProduct.product.custom_fields.some((field: EcommerceProductCustomField) => (
			productToMatch.custom_fields?.some((customField: EcommerceProductCustomField) => (
				getIsSameCustomField(field, customField)
			))));

		return isSameProduct && isSameCustomField;
	}

	return isSameProduct;
});

export const useEcommerceGlobal = ({ blockId }: { blockId?: string } = {
	blockId: 'global',
}) => {
	const {
		meta,
		pages,
	} = useSiteGlobal();

	const isStoreTypeZyro = computed(() => meta.value[META_ECOMMERCE_TYPE] === ECOMMERCE_TYPE_ZYRO);
	const isEcommerceStoreCreated = computed(() => !!meta.value?.ecommerceStoreId);
	const isLoading = computed(() => isLoadingValues.value[blockId || ''] ?? false);
	const isLoaded = computed(() => isLoadedValues.value[blockId || ''] ?? false);

	const quantifiedCartItemsList = computed(() => shoppingCartItems.value.reduce((quantifiedProducts, product) => {
		const existingProductWithCustomField = getExistingProductWithCustomField(quantifiedProducts, product);

		if (existingProductWithCustomField) {
			return quantifiedProducts.map((productToUpdate: any) => {
				const isSameProduct = productToUpdate.product.variants[0].id === product.variants[0].id;
				const isSameCustomField = productToUpdate.product.custom_fields?.length
					? productToUpdate.product.custom_fields?.some((field: EcommerceProductCustomField) => (
						product.custom_fields?.some((customField: EcommerceProductCustomField) => (
							getIsSameCustomField(field, customField)
						))
					))
					: true;

				if (isSameProduct && isSameCustomField) {
					return {
						...productToUpdate,
						quantity: productToUpdate.quantity + 1,
					};
				}

				return productToUpdate;
			});
		}

		return [
			...quantifiedProducts,
			{
				product,
				quantity: 1,
			},
		];
	}, []));
	const shoppingCartItemCount = computed(() => {
		const freeProductIds: string[] = shoppingCartItems.value
			.filter((product) => getIsFreeProductTypeFree(product))
			.map((product) => product.id);
		const uniqueFreeProductIds = freeProductIds.filter((value, index, self) => self.indexOf(value) === index);

		const productCountWithoutFreeProducts = shoppingCartItems.value.reduce((count, item) => {
			if (uniqueFreeProductIds.includes(item.id)) {
				return count;
			}

			return count + 1;
		}, 0);

		return productCountWithoutFreeProducts + uniqueFreeProductIds.length;
	});

	const canAddToCart = (product: EcommerceProduct, productVariantId?: string) => {
		if (!product || !productVariantId || shoppingCartItems.value.length >= MAX_PRODUCTS_IN_CART) {
			return false;
		}

		const variant = product?.variants?.find((item) => item.id === productVariantId);

		if (!variant) {
			return false;
		}

		if (variant.manage_inventory) {
			const cartProduct = quantifiedCartItemsList.value
				.find((productItem: any) => productItem.product.id === product.id
          && productItem.product.variants.some((variantItem: any) => variantItem.id === variant.id));
			const quantity = cartProduct?.quantity || 0;
			const availableQuantity = variantsQuantity.value.find((item) => item.id === productVariantId)?.inventory_quantity as number;

			return quantity < availableQuantity;
		}

		return true;
	};

	const legacyProductPages = computed<SitePage[]>(
		() => Object.values(pages.value).filter((page: any) => page.type === PAGE_TYPE_ECOMMERCE_PRODUCT) as SitePage[],
	);
	const dynamicPageTemplateData = computed<SitePage>(
		() => Object.values(pages.value).find((page: any) => page.type === PAGE_TYPE_ECOMMERCE_DYNAMIC_PRODUCT) as SitePage,
	);

	const setIsLoading = (payload: boolean) => {
		if (blockId) {
			isLoadingValues.value[blockId] = payload;
		}
	};

	const setIsLoaded = (payload: boolean) => {
		if (blockId) {
			isLoadedValues.value[blockId] = payload;
		}
	};

	const setIsCategoriesLoaded = (payload: boolean) => {
		isCategoriesLoaded.value = payload;
	};

	const setIsCheckoutLoading = (payload: boolean) => {
		isCheckoutLoading.value = payload;
	};

	const setCategories = (payload: any) => {
		categories.value = payload;
	};

	const setShoppingCartOpen = (payload: boolean) => {
		isShoppingCartOpen.value = payload;
	};

	const setShoppingCartItems = (payload: any) => {
		shoppingCartItems.value = payload || [];

		const storageValue = {
			payload,
			expiry: Date.now() + SHOPPING_CART_TTL,
		};

		window.localStorage.setItem(SHOPPING_CART_STORAGE_KEY, JSON.stringify(storageValue));
	};

	const setSelectedBookingProduct = (payload: any) => {
		selectedBookingProduct.value = payload;
	};

	const setVariantsQuantity = (payload: EcommerceProductVariantQuantity[]) => {
		const filteredOldVariants = variantsQuantity.value.filter(
			(oldVariant) => payload.findIndex((v) => v.id === oldVariant.id) === -1,
		);

		variantsQuantity.value = [
			...filteredOldVariants,
			...payload,
		];
	};

	const refreshCartItems = ({
		cartItems,
		products,
	}: { cartItems: EcommerceProduct[], products: EcommerceProduct[] }) => (
		(cartItems || shoppingCartItems.value).reduce((accumulator: any, cartItem: any) => {
			const productMatch = products.find((productItem) => productItem.id === cartItem.id);

			const variantMatch = productMatch?.variants
				.find((variantItem) => cartItem.variants
					.some((cartVariantItem: any) => variantItem.id === cartVariantItem.id));

			const customFieldMatch = cartItem?.custom_fields?.find((customFieldItem: EcommerceProductCustomField) => cartItem.custom_fields
				.some((cartCustomFieldItem: any) => customFieldItem.id === cartCustomFieldItem.id
					&& customFieldItem.value === cartCustomFieldItem.value));

			const quantity = accumulator.reduce((counter: any, item: any) => {
				const isSameVariant = item.variants.some((variant: any) => variant.id === variantMatch?.id);
				const isSameCustomField = productMatch?.custom_fields?.length
					? item.custom_fields?.every(
						(customField: any) => getIsSameCustomField(customField, customFieldMatch),
					)
					: true;

				if (isSameVariant && isSameCustomField) {
					return counter + 1;
				}

				return counter;
			}, 0);

			const availableQuantity = variantsQuantity.value.find(
				((variant) => variant.id === variantMatch?.id),
			)?.inventory_quantity as number;

			const isQuantityValid = !variantMatch?.manage_inventory || quantity < availableQuantity;

			const bookingEvent = productMatch?.type.value === EcommerceProductType.BOOKING ? {
				...cartItem.variants[0].booking_event,
				time_slot: cartItem.variants[0].booking_event.time_slot,
				date: cartItem.variants[0].booking_event.date,
			} : null;

			if (productMatch && variantMatch && isQuantityValid && customFieldMatch) {
				return [
					...accumulator,
					{
						...productMatch,
						custom_fields: cartItem.custom_fields,
						variants: [
							{
								...variantMatch,
								booking_event: bookingEvent,
							},
						],
					},
				];
			}

			if (productMatch && variantMatch && isQuantityValid) {
				return [
					...accumulator,
					{
						...productMatch,
						variants: [
							{
								...variantMatch,
								booking_event: bookingEvent,
							},
						],
					},
				];
			}

			return accumulator;
		}, []));

	const updateVariantsQuantity = async (productsToCheck: EcommerceProduct[]) => {
		const storeId = getStoreId(meta.value);

		if (!storeId || !productsToCheck) {
			return;
		}

		const idsToFetch = productsToCheck.map(({ id }) => id) as string[];

		try {
			const quantity = await getVariantsQuantity(storeId, idsToFetch);

			setVariantsQuantity(quantity);
		} catch (error) {
			console.error(error);
		}
	};

	const fetchCategories = async (storeId: string, isCategoryListEnabled: boolean) => {
		if (!isCategoryListEnabled || categories.value.length) {
			return [];
		}

		try {
			const categoriesData = await getCategories(storeId);

			return categoriesData;
		} catch (error) {
			console.error(error);

			return [];
		}
	};

	const fetchCartProducts = async () => {
		const storeId = getStoreId(meta.value);

		if (!storeId) {
			return;
		}

		const cartItems = getCartData();
		const cartProductIds: string[] = cartItems.map((product: any) => product.id);

		if (!cartProductIds.length) {
			if (!isLoaded.value) {
				setIsLoaded(true);
			}

			return;
		}

		try {
			const products = await getStoreProductsByIds(storeId, cartProductIds);

			await updateVariantsQuantity(products);

			const updatedCartItems = await refreshCartItems({
				cartItems: getCartData(),
				products,
			});

			setShoppingCartItems(updatedCartItems);
		} catch (error) {
			console.error(error);

			return;
		} finally {
			setIsLoading(false);
			setIsLoaded(true);
		}
	};

	const getProductsByIds = async (productIds: string[]) => {
		const storeId = getStoreId(meta.value);

		if (!storeId) {
			return [];
		}

		try {
			const productData = await getStoreProductsByIds(storeId, productIds);

			return productData;
		} catch (error) {
			console.error(error);

			return [];
		} finally {
			setIsLoading(false);
			setIsLoaded(true);
		}
	};

	const getShippingData = async (): Promise<EcommerceRegionShippingOptions> => {
		const storeId = getStoreId(meta.value);

		if (!storeId) {
			return [];
		}

		try {
			const regions: EcommerceRegion[] = await getStoreRegions(storeId);

			const shippingRequests = regions.flatMap(async ({
				id,
				name,
			}) => {
				const shippingOptions: EcommerceShippingOption[] = await getStoreShippingOptions(id);

				return {
					name,
					shippingOptions,
				};
			});

			const shippingOptions = await Promise.all(shippingRequests);

			return shippingOptions;
		} catch (error) {
			console.error(error);

			return [];
		} finally {
			setIsLoading(false);
			setIsLoaded(true);
		}
	};

	return {
		shoppingCartItems,
		selectedBookingProduct,
		variantsQuantity,
		isShoppingCartOpen,
		isCheckoutLoading,
		isLoading,
		isLoaded,
		isProductPageLoaded,
		isStoreTypeZyro,
		isEcommerceStoreCreated,
		quantifiedCartItemsList,
		canAddToCart,
		legacyProductPages,
		setIsLoading,
		setIsLoaded,
		setIsCheckoutLoading,
		setCategories,
		setShoppingCartOpen,
		setShoppingCartItems,
		setSelectedBookingProduct,
		setVariantsQuantity,
		fetchCartProducts,
		updateVariantsQuantity,
		refreshCartItems,
		fetchCategories,
		categories,
		setIsCategoriesLoaded,
		isCategoriesLoaded,
		shoppingCartItemCount,
		getShippingData,
		getProductsByIds,
		dynamicPageTemplateData,
	};
};
