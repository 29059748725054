import { EcommerceProduct } from '@zyro-inc/site-modules/types';
import { getBatchedArrays } from '@zyro-inc/site-modules/utils/getBatchedArrays';

const ECOMMERCE_API = `${import.meta.env.VITE_ECOMMERCE_API_URL}/store`;

export const getStoreRegions = async (storeId: string) => {
	const response = await fetch(`${ECOMMERCE_API}/${storeId}/regions`);

	const data = await response.json();

	return data.regions;
};

export const getStoreShippingOptions = async (regionId: string) => {
	const searchParams = new URLSearchParams();

	searchParams.set('region_id', regionId);

	const response = await fetch(`${ECOMMERCE_API}/shipping-options?${searchParams.toString()}`);
	const data = await response.json();

	return data.shipping_options;
};

export const getStoreProducts = async (storeId: string, options: {
	sort?: string,
	offset?: number,
	limit?: number,
	collectionId?: string,
} = {}) => {
	const {
		sort,
		offset,
		limit,
		collectionId,
	} = options;

	const searchParams = new URLSearchParams(sort);

	// typeof because offset can be 0
	if (typeof offset === 'number' && limit) {
		searchParams.set('offset', offset.toString());
		searchParams.set('limit', limit.toString());
	}

	if (collectionId) {
		searchParams.set('collection_ids[]', collectionId);
	}

	const response = await fetch(`${ECOMMERCE_API}/${storeId}/products?${searchParams.toString()}`);
	const data = await response.json();

	return data;
};

export const getStoreProductsByIds = async (storeId: string, productIds: string[]): Promise<EcommerceProduct[]> => {
	const productIdsInBatches = getBatchedArrays(productIds || [], 1);

	const fetchPromises = productIdsInBatches?.map(async (batch) => {
		const searchParams = new URLSearchParams();

		batch.forEach((id) => searchParams.append('ids[]', id));

		const response = await fetch(`${ECOMMERCE_API}/${storeId}/products?${searchParams.toString()}`);

		return response.json();
	});

	const results = await Promise.allSettled(fetchPromises);

	const fulfilledResults = results
		.filter((result) => result.status === 'fulfilled')
		.map((result) => (result as PromiseFulfilledResult<any>).value);

	return fulfilledResults.flatMap(({ products }) => products);
};

export const getStoreProductSeo = async (storeId: string) => {
	const response = await fetch(`${ECOMMERCE_API}/${storeId}/products/seo-settings`);
	const data = await response.json();

	return data;
};

export const getProductByIdOrSlug = async ({
	storeId,
	productId,
	slug,
}: {
	storeId: string,
	productId?: string,
	slug?: string
}) => {
	const queryParams = slug ? '?field=slug' : '';
	const response = await fetch(`${ECOMMERCE_API}/${storeId}/products/${productId || slug}${queryParams}`);
	const data = await response.json();

	return data.product;
};

export const getVariantsQuantity = async (storeId: string, productIds: string[]) => {
	const suffix = productIds.map((id) => `product_ids[]=${id}`).join('&');
	const response = await fetch(`${ECOMMERCE_API}/${storeId}/variants?fields=inventory_quantity&${suffix}`);
	const data = await response.json();

	return data.variants;
};

export const getCheckoutUrl = async ({
	items,
	successUrl,
	cancelUrl,
	checkoutUrl,
	locale,
	storeId,
}: {
	items: Array<{ id: string, quantity: number }>,
	successUrl: string,
	cancelUrl: string,
	checkoutUrl: string,
	locale: string,
	storeId: string,
}) => fetch(`${ECOMMERCE_API}/${storeId}/checkout`, {
	method: 'POST',
	headers: {
		Accept: 'application/json',
		'Content-Type': 'application/json',
	},
	credentials: 'omit',
	body: JSON.stringify({
		items,
		successUrl,
		cancelUrl,
		checkoutUrl,
		locale,
	}),
}).then(async (response) => {
	const data = await response.json();

	if (response.ok) {
		return data.url;
	}

	throw (data);
});

export const getTimeSlots = async (bookingId: string, date: string) => fetch(`${ECOMMERCE_API}/time-slots`, {
	method: 'POST',
	headers: {
		Accept: 'application/json',
		'Content-Type': 'application/json',
	},
	credentials: 'omit',
	body: JSON.stringify({
		booking_event_id: bookingId,
		time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
		date,
	}),
}).then(async (response) => {
	const data = await response.json();

	// TODO: fix return
	if (response.ok) {
		return data.slots;
	}

	throw (data);
});

export const getAvailability = async ({
	bookingId,
	fromDate,
	toDate,
}: {
	bookingId: string,
	fromDate: string,
	toDate: string,
}) => fetch(`${ECOMMERCE_API}/availability`, {
	method: 'POST',
	headers: {
		Accept: 'application/json',
		'Content-Type': 'application/json',
	},
	credentials: 'omit',
	body: JSON.stringify({
		booking_event_id: bookingId,
		time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
		from_date: fromDate,
		to_date: toDate,
	}),
}).then(async (response) => {
	const data = await response.json();

	if (response.ok) {
		return data.disabled_dates;
	}

	throw (data);
});

export const getCategories = async (storeId: string) => {
	const response = await fetch(`${ECOMMERCE_API}/${storeId}/collections`);
	const data = await response.json();

	return data;
};
