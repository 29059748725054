<script setup lang="ts">
import {
	computed,
	onMounted,
} from 'vue';
import { useRoute } from 'vue-router';

import Page from '@zyro-inc/site-modules/components/Page.vue';

import {
	SYSTEM_LOCALE,
	PAGE_ID_PRIVATE,
} from '@zyro-inc/site-modules/constants';
import { getPathParams } from '@zyro-inc/site-modules/utils/page/getPathParams';
import { getPageIdFromPath } from '@zyro-inc/site-modules/utils/page/getPageIdFromPath';
import {
	getPageData,
	getShouldLoadDynamicProductPageByPageId,
	getDynamicProductPageData,
} from '@zyro-inc/site-modules/utils/getPageData';
import { useSiteGlobal } from '@zyro-inc/site-modules/use/useSiteGlobal';
import { getIsDynamicProductPageEnabled } from '@zyro-inc/site-modules/utils/ecommerce';

const {
	website,
	setIsSiteEngine,
	productSeo,
	fetchProductSeo,
	ecommerceStoreId,
} = useSiteGlobal();
const route = useRoute();

const defaultLocale = computed(() => website.value?.meta.defaultLocale ?? SYSTEM_LOCALE);

const currentLocale = computed(() => {
	if (!website.value) {
		return null;
	}

	if (website.value.meta.isPrivateModeActive) {
		return SYSTEM_LOCALE;
	}

	const { locale } = getPathParams({
		path: route.path,
		defaultLocale: defaultLocale.value,
		languageKeys: Object.keys(website.value.languages),
	});

	return locale;
});
const isDynamicProductPageEnabled = computed(() => getIsDynamicProductPageEnabled({
	defaultLocalePages: website.value?.languages[defaultLocale.value]?.pages,
}));

const pageData = computed(() => {
	if (!website.value) {
		return null;
	}

	const pageId = website.value.meta.isPrivateModeActive
		? PAGE_ID_PRIVATE
		: getPageIdFromPath({
			path: route.path,
			siteData: website.value,
			products: productSeo.value || [],
		});

	const shouldLoadDynamicProductPage = getShouldLoadDynamicProductPageByPageId({
		pageId,
		website: website.value,
		path: route.path,
		defaultLocale: defaultLocale.value,
	});

	if (shouldLoadDynamicProductPage) {
		return getDynamicProductPageData({
			website: website.value,
			defaultLocale: defaultLocale.value,
		});
	}

	return getPageData({
		siteData: website.value,
		locale: currentLocale.value,
		pageId,
		isAstro: false,
		isPreviewMode: false,
		isCustomCodeDisabled: website.value.meta.isCustomCodeDisabled || false,
	});
});

const getProductsSeo = async () => {
	if (isDynamicProductPageEnabled.value || productSeo.value?.length) {
		return;
	}

	await fetchProductSeo(ecommerceStoreId.value);
};

setIsSiteEngine(true);

onMounted(async () => {
	await getProductsSeo();
});
</script>

<template>
	<Page
		v-if="pageData"
		:page-data="pageData"
	/>
</template>
